import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import {
  AppCommunity,
  AppCommunityPost,
  AppLogin,
  AppSelectType,
  AppWritePost,
  AppEstimateList,
  Root,
  AppEstimateInfo,
  AppEstimateDetail,
  AppEstimateSubmit,
  AppEstimateInfoSubmit,
  AppSearchMaster,
  AppMasterInfo,
} from '../pages';

const App: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Root} />
      <Route exact path="/app/login" component={AppLogin} />
      <Route exact path="/app/select-type" component={AppSelectType} />
      <Route exact path="/app/community" component={AppCommunity} />
      <Route
        exact
        path="/app/community/:postIdx"
        component={AppCommunityPost}
      />
      <Route exact path="/app/write-post" component={AppWritePost} />
      <Route exact path="/app/estimate-list" component={AppEstimateList} />
      <Route exact path="/app/estimate-info" component={AppEstimateInfo} />
      <Route exact path="/app/estimate-detail" component={AppEstimateDetail} />
      <Route exact path="/app/estimate-submit" component={AppEstimateSubmit} />
      <Route
        exact
        path="/app/estimate-info-submit"
        component={AppEstimateInfoSubmit}
      />
      <Route exact path="/app/search-master" component={AppSearchMaster} />
      <Route exact path="/app/master-info" component={AppMasterInfo} />
    </Switch>
  </BrowserRouter>
);

export default App;
