import loadable from '@loadable/component';

export const Root = loadable(() => import('./Root'));
export const AppLogin = loadable(() => import('./app/Login'));
export const AppSelectType = loadable(() => import('./app/SelectType'));
export const AppCommunity = loadable(() => import('./app/Community'));
export const AppCommunityPost = loadable(() => import('./app/CommunityPost'));
export const AppWritePost = loadable(() => import('./app/WritePost'));
export const AppEstimateList = loadable(() => import('./app/EstimateList'));
export const AppEstimateInfo = loadable(() => import('./app/EstimateInfo'));
export const AppEstimateDetail = loadable(() => import('./app/EstimateDetail'));
export const AppEstimateSubmit = loadable(() => import('./app/EstimateSubmit'));
export const AppEstimateInfoSubmit = loadable(
  () => import('./app/EstimateInfoSubmit'),
);
export const AppSearchMaster = loadable(() => import('./app/SearchMaster'));
export const AppMasterInfo = loadable(() => import('./app/MasterInfo'));
